<template>
  <v-row>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-col cols="12" sm="4" lg="2">
      <h4>Kunden Status
        <v-tooltip right color="" max-width="200px">
          <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="d-inline-flex" size="20">
                          mdi-help
                        </v-icon>
                      </span>
          </template>
          Lege fest welchen Status ein Kunde besitzen kann. Bspw.
          "Interessent", "Kunde" oder "Ex-Kunde".
        </v-tooltip>
      </h4>
    </v-col>
    <v-col>
      <div v-for="status of customerStatus" :key="status.id" class="pb-3">
        <span :style="'border-left: 7px solid '+status.color" class="ps-1">{{ status.name }}</span>
        <v-tooltip right color="red">
          <template v-slot:activator="{ on, attrs }">
                        <span
                            v-bind="attrs"
                            v-on="on"
                        >
                      <v-icon @click="sureToDeleteDialog = true; selectedId = status.id">
                      mdi-delete
                      </v-icon>
                        </span>
          </template>
          Status löschen
        </v-tooltip>

      </div>
      <SureToDelete
          class="mt-0 pt-0 mb-0 pb-0"
          :dialog="sureToDeleteDialog"

          :text="'Bist du Sicher, dass du den Status unwiderruflich löschen möchtest?'"
          @cancel="sureToDeleteDialog=false"
          @sure="deleteStatus('customerStatus'); sureToDeleteDialog=false"
      ></SureToDelete>
      <v-row class="align-center">
        <v-col cols="1" md="1">
          <a><div @click="toggleColor" class="rounded-xl" style="height: 25px;width: 25px" :style="'background-color: ' + newCustomerStatusColor"></div>
          </a></v-col>
        <v-col cols="7" md="5">
          <v-text-field label="Neuer Status" placeholder="Neuer Status" v-model="newCustomerStatus"></v-text-field>
        </v-col>
        <v-col cols="4" md="6">
            <a  @click="addCustomerStatus('customerStatus', newCustomerStatus)" class="d-inline">
              Hinzufügen
            </a>
        </v-col>
      </v-row>
      <v-row v-if="showColor">
        <v-color-picker  placeholder="Neuer Status" v-model="newCustomerStatusColor" ></v-color-picker>
        <v-text-field label="HEX" class="pa-3" placeholder="HEX" v-model="newCustomerStatusColor"></v-text-field>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");
const SureToDelete = () => import("@/components/generalUI/SureToDelete");
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
  name: "CustomerStatus",
  computed: {
    ...mapGetters('settings', {
      customerStatus: 'customerStatus',
    }),
  },
  components: {
    Message,
    SureToDelete
  },
  data() {
    return {
      newCustomerStatus: '',
      newCustomerStatusColor:'#6D7E6D',
      message: msgObj(),
      showColor: false,
      sureToDeleteDialog:false,
      selectedId:""
    }
  },
  mounted() {
    this.$store.dispatch('settings/getStatus', {
      uid: this.user.id,
    }).catch((err) => {
      this.message = error(err)
    })
  },
  props: {
    user: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  methods: {
    toggleColor(){
      this.showColor = !this.showColor
    },
    deleteStatus(statusCollection) {
      this.$store.dispatch('settings/deleteStatus', {
        uid: this.user.id,
        id: this.selectedId,
        statusCollection,
      }).then(() => {
        this.message = success('Status erfolgreich gelöscht.')
      }).catch((err) => {
        this.message = error(err)
      })
    },
    addCustomerStatus(statusCollection, name) {
      if (name !== '') {
        this.$store.dispatch('settings/addCustomerStatus', {
          uid: this.user.id,
          statusCollection,
          name,
          color: this.newCustomerStatusColor
        }).then(() => {
          this.newCustomerStatus = ''
          this.newCategory = ''
          this.message = success('Feld erfolgreich angelegt.')
        }).catch((err) => {
          this.message = error(err)
        })
      }
    },
  }
}
</script>
